import { useMemo } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import Callback from '@/pages/Callback';
import Forbidden from '@/pages/Forbidden';

const AnonymousContainer = () => {
  const router = useMemo(
    () =>
      createBrowserRouter(
        createRoutesFromElements(
          <Route>
            <Route path="/callback" element={<Callback />} />
            <Route path="*" element={<Forbidden />} />
          </Route>
        )
      ),
    []
  );

  return <RouterProvider router={router} />;
};

export default AnonymousContainer;
