export var LiveScopes;
(function (LiveScopes) {
    LiveScopes["ReadStatistics"] = "read:statistics";
    LiveScopes["ReadLeads"] = "read:leads";
    LiveScopes["UpdateLeads"] = "update:leads";
})(LiveScopes || (LiveScopes = {}));
export const logtoLive = {
    indicator: 'https://live.logto.io',
    scopes: LiveScopes,
};
