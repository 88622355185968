import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Chip, { type ChipTypeMap } from '@mui/material/Chip';
import { conditional, conditionalArray, isKeyInObject } from '@silverhand/essentials';
import type ky from 'ky';
import { MaterialReactTable, type MRT_Cell, type MRT_ColumnDef } from 'material-react-table';
import { Suspense } from 'react';
import { Await, defer, useLoaderData } from 'react-router-dom';

import Status from './Status';

export const leadsLoader = (api: typeof ky) => (): ReturnType<typeof defer> => {
  return defer({
    data: api.get('leads').json(),
  });
};

const renderTags = (values: unknown[], color?: ChipTypeMap['props']['color']) => (
  <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>
    {values.map((value) => (
      <Chip
        key={String(value)}
        label={String(value)}
        color={color}
        variant="outlined"
        size="small"
      />
    ))}
  </div>
);

const renderCell = (cell: MRT_Cell<Record<string, unknown>>) => {
  const value = cell.getValue();

  if (!value) {
    return null;
  }

  if (Array.isArray(value)) {
    return renderTags(value);
  }

  if (typeof value === 'object') {
    return JSON.stringify(value);
  }

  return <span>{String(value)}</span>;
};

const renderBool = (cell: MRT_Cell<Record<string, unknown>>) =>
  cell.getValue() ? <CheckCircleIcon color="success" /> : null;

const columns: Array<MRT_ColumnDef<Record<string, unknown>>> = [
  {
    accessorKey: 'leadData.status',
    header: 'Status',
    size: 120,
    Cell: Status,
  },
  { accessorKey: 'id', header: 'ID' },
  { accessorKey: 'name', header: 'Name', size: 160 },
  { accessorKey: 'primaryEmail', header: 'Email' },
  {
    accessorKey: 'customData.onboarding.isOnboardingDone',
    size: 80,
    header: 'Done?',
    Cell: ({ cell }) => renderBool(cell),
  },
  ...['titles', 'reasons', 'companyName', 'companySize', 'project', 'deploymentType'].map<
    MRT_ColumnDef<Record<string, unknown>>
  >((key) => ({
    id: key,
    accessorKey: `customData.onboarding.questionnaire.${key}`,
    header: key[0] ? key[0].toUpperCase() + key.slice(1) : 'Unknown',
    Cell: ({ cell }) => renderCell(cell),
  })),
  {
    id: 'socialSource',
    accessorFn: ({ identities }) =>
      conditionalArray(
        ...['GitHub', 'Google'].map((source) =>
          conditional(isKeyInObject(identities, source.toLowerCase()) && source)
        )
      ),
    header: 'Social Source',
    Cell: ({ cell }) => renderTags(cell.getValue<string[]>()),
  },
  {
    accessorKey: 'customData.adminConsolePreferences',
    header: 'Preference',
    Cell: ({ cell }) => renderCell(cell),
  },
];

const Table: typeof MaterialReactTable<Record<string, unknown>> = MaterialReactTable;

const Leads = () => {
  const leads = useLoaderData();

  if (!isKeyInObject(leads, 'data')) {
    return <div>Type error</div>;
  }

  return (
    <Suspense fallback={<p>Loading...</p>}>
      <Await resolve={leads.data} errorElement={<p>Error loading data</p>}>
        {(data) => (
          <div>
            {Array.isArray(data) ? (
              <Table
                columns={columns}
                data={data}
                getRowId={(row) => String(row.id)}
                initialState={{
                  density: 'compact',
                  pagination: { pageIndex: 0, pageSize: 100 },
                  columnVisibility: { id: false },
                  showColumnFilters: true,
                  columnFilters: [
                    { id: 'project', value: 'company' },
                    { id: 'deploymentType', value: 'cloud' },
                  ],
                }}
                muiTableContainerProps={{
                  sx: { maxHeight: '85vh', minHeight: 640, width: '100%' },
                }}
                muiPaginationProps={{
                  rowsPerPageOptions: [],
                  showFirstButton: false,
                  showLastButton: false,
                }}
              />
            ) : (
              'Loading'
            )}
          </div>
        )}
      </Await>
    </Suspense>
  );
};

export default Leads;
