const getAdminTenantEndpoint = () => {
  // Allow endpoint override for dev or testing
  if (process.env.ADMIN_ENDPOINT) {
    return new URL(process.env.ADMIN_ENDPOINT);
  }

  return new URL(window.location.origin.replace('live.', 'auth.'));
};

export const adminTenantEndpoint = getAdminTenantEndpoint();
