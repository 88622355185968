import { LiveScopes } from '@logto/live/constants/api';
import { useLogto } from '@logto/react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useMemo } from 'react';
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import useApi from '@/hooks/use-api';
import Forbidden from '@/pages/Forbidden';
import Home, { statisticsLoader } from '@/pages/Home';
import Leads, { leadsLoader } from '@/pages/Leads';

type TabItem = {
  id: string;
  path: string;
  needScope: LiveScopes;
};

const tabs: TabItem[] = [
  { id: 'home', path: '/', needScope: LiveScopes.ReadStatistics },
  // Hide temporarily
  // { id: 'leads', path: '/leads', needScope: LiveScopes.ReadLeads },
];

const Main = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentTab = tabs.find(({ path }) => pathname === path);

  return (
    <>
      <Tabs value={currentTab?.path} aria-label="Leads table">
        {tabs.map(({ id, path }) => (
          <Tab
            key={id}
            label={id}
            value={path}
            onClick={() => {
              navigate(path);
            }}
          />
        ))}
      </Tabs>
      <Outlet />
    </>
  );
};

const MainContainer = () => {
  const { isAuthenticated, signOut, getIdTokenClaims } = useLogto();
  const api = useApi();
  const router = useMemo(
    () =>
      createBrowserRouter(
        createRoutesFromElements(
          <Route element={<Main />}>
            <Route path="/" element={<Home />} loader={statisticsLoader(api)} />
            <Route
              path="/leads"
              element={<Leads />}
              action={async ({ request }) => {
                const data = await request.formData();
                const claims = await getIdTokenClaims();
                const id = data.get('id');

                if (!id) {
                  throw new Error('id is missing');
                }

                return api.put(`leads/${String(id)}`, {
                  json: {
                    data: {
                      status: data.get('status'),
                      updatedBy: claims?.sub,
                      updatedAt: new Date(),
                    },
                  },
                });
              }}
              loader={leadsLoader(api)}
            />
          </Route>
        )
      ),
    [api, getIdTokenClaims]
  );

  if (!isAuthenticated) {
    return <Forbidden />;
  }

  return (
    <>
      <button onClick={async () => signOut(new URL(window.location.origin).href)}>Sign out</button>
      <RouterProvider router={router} />
    </>
  );
};
export default MainContainer;
