import { logtoLive } from '@logto/live/constants/api';
import { LogtoProvider, useLogto } from '@logto/react';

import AnonymousContainer from './container/AnonymousContainer';
import MainContainer from './container/MainContainer';
import { adminTenantEndpoint } from './utils/consts';

const Content = () => {
  const { isAuthenticated } = useLogto();

  if (isAuthenticated) {
    return <MainContainer />;
  }

  return <AnonymousContainer />;
};

const App = () => {
  return (
    <LogtoProvider
      config={{
        endpoint: adminTenantEndpoint.href,
        appId: '2a1yu6nmi14x2w2pr3ua4',
        resources: [logtoLive.indicator],
        scopes: Object.values(logtoLive.scopes),
      }}
    >
      <Content />
    </LogtoProvider>
  );
};

export default App;
