import { useLogto } from '@logto/react';
import { appendPath } from '@silverhand/essentials';

const Forbidden = () => {
  const { signIn } = useLogto();

  return (
    <div>
      Forbidden{' '}
      <button
        onClick={async () => signIn(appendPath(new URL(window.location.origin), 'callback').href)}
      >
        Sign in
      </button>
    </div>
  );
};
export default Forbidden;
