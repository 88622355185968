import { useHandleSignInCallback } from '@logto/react';
import { useNavigate } from 'react-router-dom';

const Callback = () => {
  const navigate = useNavigate();
  const { error } = useHandleSignInCallback(() => {
    navigate('/');
  });

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return <div>Redirecting...</div>;
};

export default Callback;
