import { logtoLive } from '@logto/live/constants/api';
import { useLogto } from '@logto/react';
import { appendPath } from '@silverhand/essentials';
import ky from 'ky';
import { useMemo } from 'react';

const useApi = (): typeof ky => {
  const { isAuthenticated, getAccessToken } = useLogto();

  const api = useMemo(
    () =>
      ky.create({
        prefixUrl: appendPath(new URL(window.location.origin), 'api'),
        timeout: 20_000,
        hooks: {
          beforeRequest: [
            async (request) => {
              if (isAuthenticated) {
                const accessToken = await getAccessToken(logtoLive.indicator);
                request.headers.set('Authorization', `Bearer ${accessToken ?? ''}`);
              }
            },
          ],
        },
      }),
    [isAuthenticated, getAccessToken]
  );

  return api;
};

export default useApi;
