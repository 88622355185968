import { LineChart, CartesianGrid, XAxis, YAxis, Legend, Line, Tooltip } from 'recharts';

export type UserChartData = Array<{
  date: string;
  newUsers: number;
  activeUsers: number;
}>;

type Props = {
  readonly data: UserChartData;
  readonly formatDate: (date: string) => string;
};

const UserChart = ({ data, formatDate }: Props) => {
  return (
    <LineChart
      width={730}
      height={250}
      data={data}
      margin={{ top: 16, right: 16, left: 24, bottom: 16 }}
    >
      <CartesianGrid strokeDasharray="3 3" vertical={false} />
      <XAxis dataKey="date" tickFormatter={formatDate} />
      <YAxis allowDecimals={false} />
      <Tooltip labelFormatter={formatDate} />
      <Legend />
      <Line type="monotone" dot={false} dataKey="newUsers" stroke="#8884d8" />
      <Line type="monotone" dot={false} dataKey="activeUsers" stroke="#82ca9d" />
    </LineChart>
  );
};

export default UserChart;
