import EditIcon from '@mui/icons-material/Edit';
import Chip, { type ChipTypeMap } from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import NativeSelect from '@mui/material/NativeSelect';
import { type MRT_Cell } from 'material-react-table';
import { useCallback, useState } from 'react';
import { useFetcher } from 'react-router-dom';

type Props = {
  readonly cell: MRT_Cell<Record<string, unknown>>;
};

enum LeadStatus {
  Pending = 'Pending',
  EmailSent = 'Email sent',
  Valuable = 'Valuable',
  Invalid = 'Invalid',
}

const leadStatusValues = Object.freeze(Object.values(LeadStatus));

const getColor = (status: LeadStatus): ChipTypeMap['props']['color'] => {
  switch (status) {
    case LeadStatus.EmailSent: {
      return 'primary';
    }
    case LeadStatus.Valuable: {
      return 'warning';
    }
    default: {
      return 'default';
    }
  }
};

const Status = ({ cell }: Props) => {
  const [editMode, setEditMode] = useState(false);
  const status = leadStatusValues.find((value) => value === cell.getValue()) ?? LeadStatus.Pending;
  const fetcher = useFetcher();
  const handleStatusChange: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    async ({ target: { value } }) => {
      const data = new FormData();
      data.set('id', cell.row.id);
      data.set('status', value);
      fetcher.submit(data, { method: 'put', action: `/leads` });
      setEditMode(false);
    },
    [cell.row.id, fetcher]
  );

  if (status !== LeadStatus.Pending && !editMode) {
    return (
      <div>
        <Chip label={status} size="small" color={getColor(status)} />
        <IconButton
          aria-label="edit"
          size="small"
          onClick={() => {
            setEditMode(true);
          }}
        >
          <EditIcon fontSize="inherit" />
        </IconButton>
      </div>
    );
  }

  return (
    <NativeSelect value={status} onChange={handleStatusChange}>
      {leadStatusValues.map((value) => (
        <option key={value} value={value} label={value} />
      ))}
    </NativeSelect>
  );
};
export default Status;
