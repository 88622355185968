import { type NonNullStatistic } from '@logto/live/models/statistics';
import Grid from '@mui/material/Grid';
import { isKeyInObject } from '@silverhand/essentials';
import { addDays, format, min, subDays } from 'date-fns';
import type ky from 'ky';
import { Suspense } from 'react';
import { Await, defer, useLoaderData } from 'react-router-dom';

import UserChart, { type UserChartData } from './UserChart';

export const statisticsLoader = (api: typeof ky) => (): ReturnType<typeof defer> => {
  return defer({
    admin: api
      .get('statistics', {
        searchParams: { startDate: subDays(new Date(), 45).toISOString() },
      })
      .json(),
    endUser: api
      .get('statistics', {
        searchParams: { startDate: subDays(new Date(), 45).toISOString(), forAdmin: 'false' },
      })
      .json(),
  });
};

type ApiResponse = Array<Omit<NonNullStatistic, 'date'> & { date: string }>;

const aggregateData = (data: ApiResponse, byDays = 1): UserChartData => {
  const result: UserChartData = [];

  // eslint-disable-next-line @silverhand/fp/no-let, @silverhand/fp/no-mutation
  for (let index = 0; index < data.length; index += byDays) {
    const segment = data.slice(index, index + byDays);
    // eslint-disable-next-line @silverhand/fp/no-mutating-methods
    result.push({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      date: segment[0]!.date,
      newUsers: new Set(segment.flatMap(({ newUsers }) => newUsers)).size,
      activeUsers: new Set(segment.flatMap(({ activeUsers }) => activeUsers)).size,
    });
  }

  return result;
};

const formatDate = (date: string, gapByDays = 1) => {
  const start = new Date(date);

  if (gapByDays <= 1) {
    return format(start, 'yyyy-MM-dd');
  }
  return `${format(start, 'yyyy-MM-dd')} to ${format(
    min([addDays(start, gapByDays - 1), new Date()]),
    'MM-dd'
  )}`;
};

const Home = () => {
  const stats = useLoaderData();

  if (!isKeyInObject(stats, 'admin') || !isKeyInObject(stats, 'endUser')) {
    return <div>Type error</div>;
  }

  return (
    <div>
      <Suspense fallback={<p>Loading...</p>}>
        <Grid container spacing={2}>
          {(['admin', 'endUser'] as const).map((key) => (
            <Await key={key} resolve={stats[key]} errorElement={<p>Error loading data</p>}>
              {(data: ApiResponse) => (
                <>
                  <Grid item xs={12} xl={6}>
                    <h2 style={{ marginLeft: 24 }}>Daily users ({key})</h2>
                    <UserChart
                      data={aggregateData(data)}
                      formatDate={(value) => formatDate(value)}
                    />
                  </Grid>
                  <Grid item xs={12} xl={6}>
                    <h2 style={{ marginLeft: 24 }}>Weekly users ({key})</h2>
                    <UserChart
                      data={aggregateData(data, 7)}
                      formatDate={(value) => formatDate(value, 7)}
                    />
                  </Grid>
                </>
              )}
            </Await>
          ))}
        </Grid>
      </Suspense>
    </div>
  );
};

export default Home;
